import { Controller } from "stimulus"

export default class extends Controller {

  connect(){
    $('#send-input').attr("disabled",true);
    $('#send-input').css('cursor', 'not-allowed');
  }

  checkInput(e){
  }

}

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "value" ]

  increment(){
    event.preventDefault();
    if (this.valueTarget.value < 6){
      this.valueTarget.value = parseInt(this.valueTarget.value) + 1
    }
  }

  decrement(){
    event.preventDefault()
    if (this.valueTarget.value > 1){
      this.valueTarget.value = parseInt(this.valueTarget.value) - 1
    }
  }
}

import { Controller } from "stimulus"

export default class extends Controller {

  close(){
    $('.flash-messages-container').empty()
  }

  closeFlash(){
    $(event.target).remove()
  }

}

import { Controller } from "stimulus"

export default class extends Controller {

  connect(){
    this.share_path = this.data.get("share-path");
  }

  startChat(){
    Turbolinks.visit(this.share_path, { action: "replace" });
  }

}

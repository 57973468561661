import { Controller } from "stimulus"

export default class extends Controller {

  displayConfirmation(){
    var planning_id = this.data.get("planning")
    $('.js-refusal_confirmation').filter('[data-planning-id="' + planning_id + '"]').show()
    $('.js-request-form').filter('[data-planning-id="' + planning_id + '"]').hide()
    if($(window).width() < 768 ) {
      $("html, body").animate({ scrollTop: $('html, body').get(0).scrollHeight}, 1000);
    }
  }

  displayRequestForm(){
    var planning_id = this.data.get("planning")
    $(".js-refusal_confirmation").filter('[data-planning-id="' + planning_id + '"]').hide()
    $(".js-request-form").filter('[data-planning-id="' + planning_id + '"]').show()
    if($(window).width() < 768 ) {
      $("html, body").animate({ scrollTop: $('html, body').get(0).scrollHeight}, 1000);
    }
  }

}

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["googleMap", "cards"]

  connect() {
    var context;
    this.first_call = true
    this.icon = { url: this.data.get("marker-icon"), scaledSize: { width: 48, height: 48 } };
    this.icon_on = { url: this.data.get("marker-icon-on"), scaledSize: { width: 48, height: 48 } };
    this.icon_me = { url: this.data.get("marker-icon-me"), scaledSize: { width: 32, height: 32 } };
    this.markers = []
    this.markerCluster;
    if (typeof (google) != "undefined"){
      this.initializeMap()
    }
    // context = this
    // $( "#shares_url" ).on("click",function() {
    //   context.handleSuccess()
    // });
  }

  initializeMap() {
    this.map()
    this.marker()
  }

  map() {
    var context;
    if(this._map == undefined) {
      this._map = new google.maps.Map(this.googleMapTarget, {
        center: new google.maps.LatLng(
          this.data.get("lat"),
          this.data.get("lng")
        ),
        zoom: Number(this.data.get("zoom")),
        zoomControl: true,
        zoomControlOptions: {
          position: google.maps.ControlPosition.TOP_LEFT
        },
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false
      });
      this._map.addListener("zoom_changed", handler)
      this._map.addListener("dragend", handler)
      context = this
      function handler(){
        context.handler()
      }
    }
  }

  handler(){
    var bounds = this._map.getBounds();
    var map_zoom = this._map.getZoom();
    var bounds_array = [];
    var latitude = this.data.get("lat")
    var longitude = this.data.get("lng")
    var geolocated = this.data.get("geolocated")
    var where = this.data.get("where")
    var when = this.data.get("when")
    var category = this.data.get("category")
    var context = this

    if(bounds) {
      let northeast = bounds.getNorthEast()
      let southwest = bounds.getSouthWest()
      bounds_array = [southwest.lat(), southwest.lng(), northeast.lat(), northeast.lng()]
      latitude = bounds.getCenter().lat()
      longitude = bounds.getCenter().lng()
      document.getElementById("bounds-filters").value = bounds_array;
    }

    let categoryParams = {};
    if (category) {
      let categories = JSON.parse(category);
      categories.forEach(value => {
        categoryParams[`category[${value}]`] = value;
      });
    }

    var url = $("#shares_url").data("url") + "?" + $.param({bounds: bounds_array, latitude: latitude, longitude: longitude, map_zoom: map_zoom, where: where, when: when, ...categoryParams, geolocated: geolocated})
    $("#shares_url").attr("href", url)
    $("#test_url").attr("href", url)
    if (!this.first_call){
      document.getElementById("shares_url").click()
    }
    window.history.pushState(history.state,"<%= @title %>", url);
    this.first_call = false
  }

  destroyMarkers() {
    var results = this.cardsTargets
    var resultIds = results.map(result => $(result).data("activity-id"));

    this.markers.forEach((marker) => {
        if (!resultIds.includes(marker.id)) {
            marker.setMap(null);
            this.markers.splice(this.markers.indexOf(marker), 1);
        }
    });

    this.markerCluster.clearMarkers();
    this.updateMap()
  }

  marker(){
    var markers = this.markers
    var map = this._map
    var icon = this.icon
    var icon_on = this.icon_on
    var icon_me = this.icon_me
    var geolocated = this.data.get("geolocated")
    var locationLatLng = new google.maps.LatLng(this.data.get("lat"),this.data.get("lng"))
    var latLngs = [
        locationLatLng,
      ]
    var bounds = new google.maps.LatLngBounds();
    var results = this.cardsTargets

    results.forEach(function(result){
      var id = $(result).data("activity-id")

      var lat = $(result).data("activity-lat")
      var lng = $(result).data("activity-lng")
      var title = $(result).data("activity-title")
      var content = $("#"+id).html()

      $(result).data("activity-id")

      var marker = new google.maps.Marker({
        position: new google.maps.LatLng(lat,lng),
        map: map,
        animation: google.maps.Animation.DROP,
        title: title,
        icon: icon,
        id: id
      });

      bounds.extend( marker.getPosition() );
      latLngs.push(new google.maps.LatLng(lat,lng))

      marker.addListener('mouseover', function(){
        $("#"+this.id).children().addClass("shadow-lg");
        this.setIcon(icon_on);
      });
      marker.addListener('mouseout', function(){
        $("#"+this.id).children().removeClass("shadow-lg");
        this.setIcon(icon);
      });

      var infowindow = new google.maps.InfoWindow({
        maxWidth: 263
      });

      google.maps.event.addListener(marker,'click', (function(marker,content,infowindow){
          return function() {
              infowindow.setContent(content);
              infowindow.open(map,marker);
          };
      })(marker,content,infowindow));

      markers.push(marker)
    });

    if (geolocated == "true"){
      var geolocated_lat = this.data.get("lat")
      var geolocated_lng = this.data.get("lng")
      var marker = new google.maps.Marker({
        position: new google.maps.LatLng(geolocated_lat,geolocated_lng),
        map: map,
        animation: google.maps.Animation.DROP,
        title: "Me",
        icon: icon_me,
        id: "0"
      });
    }

    var latLngBounds = new google.maps.LatLngBounds();
    latLngs.forEach(function (latLng) {
      latLngBounds.extend(latLng)
    })

    var icon_cluster = "/assets/front/m"
    this.markerCluster = new MarkerClusterer(map, markers, {
      imagePath: icon_cluster,
      //gridSize: 90,
      gridSize: 25,
      minimumClusterSize: 2,
    });

    if (results.length > 0){
      map.fitBounds(latLngBounds)
    }
  }

  highlightMarkers(event){
    for (let i = 0; i < this.markers.length; i++) {
      if (this.markers[i].id == $(event.target).data("activity-id")) {
        this.markers[i].setIcon(this.icon_on);
      }
    }
  }

  resetHighlight(event){
    for (let i = 0; i < this.markers.length; i++) {
      if (this.markers[i].id == $(event.target).data("activity-id")) {
        this.markers[i].setIcon(this.icon);
      }
    }
  }

  handleSuccess(){
    this.destroyMarkers()
  }

  updateMap(){
    var markers = this.markers
    var map = this._map
    var icon = this.icon
    var icon_on = this.icon_on
    var locationLatLng = new google.maps.LatLng(this.data.get("lat"),this.data.get("lng"))
    var latLngs = [
        locationLatLng,
      ]
    var bounds = new google.maps.LatLngBounds();
    var results = this.cardsTargets

    results.forEach(function(result){
      var id = $(result).data("activity-id")

      // Vérifier si le marqueur avec cet ID existe déjà
      var existingMarker = markers.find(function(marker) {
          return marker.id === id;
      });

      // Si le marqueur existe déjà, ne rien faire
      if (existingMarker) {
          return;
      }
      var lat = $(result).data("activity-lat")
      var lng = $(result).data("activity-lng")
      var title = $(result).data("activity-title")
      var content = $("#"+id).html()

      $(result).data("activity-id")

      var marker = new google.maps.Marker({
        position: new google.maps.LatLng(lat,lng),
        map: map,
        animation: google.maps.Animation.DROP,
        title: title,
        icon: icon,
        id: id
      });

      bounds.extend( marker.getPosition() );
      latLngs.push(new google.maps.LatLng(lat,lng))

      marker.addListener('mouseover', function(){
        $("#"+this.id).children().addClass("shadow-lg");
        this.setIcon(icon_on);
      });
      marker.addListener('mouseout', function(){
        $("#"+this.id).children().removeClass("shadow-lg");
        this.setIcon(icon);
      });

      var infowindow = new google.maps.InfoWindow({
        maxWidth: 263
      });

      google.maps.event.addListener(marker,'click', (function(marker,content,infowindow){
          return function() {
              infowindow.setContent(content);
              infowindow.open(map,marker);
          };
      })(marker,content,infowindow));

      markers.push(marker)
    });

    var latLngBounds = new google.maps.LatLngBounds();
    latLngs.forEach(function (latLng) {
      latLngBounds.extend(latLng)
    })

    var icon_cluster = "/assets/front/m"
    this.markerCluster = new MarkerClusterer(map, markers, {
      imagePath: icon_cluster,
      //gridSize: 90,
      gridSize: 25,
      minimumClusterSize: 2,
    });
    this.markers = markers
  }

}

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "childrenLabel", "childrenInput" ]

  connect(){
    if(this.childrenInputTarget.checked){
      this.childrenLabelTarget.classList.add('is-checked');
    }
    else{
      this.childrenLabelTarget.classList.remove('is-checked');
    }
  }

  checkChildren(){
    this.childrenLabelTarget.classList.toggle('is-checked');
  }
}

import { Controller } from "stimulus"

export default class extends Controller {

  checkCurrentPassword(){
    var e = $(event.target)
    if( e.val() == $('#new_password_input').val()){
      $("#submit-button").attr("disabled", true);
      $("#submit-button").css('cursor', 'not-allowed');
      $("#current_password_input").css("border-color", "red")
      $(".has-error").show()
    }
    else{
      $("#submit-button").attr("disabled", false);
      $("#submit-button").css('cursor', 'pointer');
      $("#current_password_input").css("border-color", "")
      $(".has-error").hide()
    }
  }

  checkNewPassword(){
    var e = $(event.target)
    if( e.val() == $('#current_password_input').val()){
      $("#submit-button").attr("disabled", true);
      $("#submit-button").css('cursor', 'not-allowed');
      $("#current_password_input").css("border-color", "red")
      $(".has-error").show()
    }
    else{
      $("#submit-button").attr("disabled", false);
      $("#submit-button").css('cursor', 'pointer');
      $("#current_password_input").css("border-color", "")
      $(".has-error").hide()
    }
  }

}

import { Controller } from "stimulus"

export default class extends Controller {
   static targets = [ "sportsTrainerInput" ]

  closeModal(event){
    event.preventDefault();
    $(event.target).parents('.modal-wrapper').removeClass('open');
    $(event.target).parents('.js-modal').addClass('hidden');
  }

  openModal(event){
    $(event.target).siblings('.js-modal').removeClass('hidden');
    $(event.target).siblings('.js-modal').find('.modal-wrapper').addClass('open');
  }

  openSportsModal(event){
    var is_sports_trainer = this.data.get("sports-trainer")
    if (is_sports_trainer != "true"){
      if(this.sportsTrainerInputTarget.checked){
        $('.js-modal').removeClass('hidden');
        $('.js-modal').find('.modal-wrapper').addClass('open');
      }
    }
  }

  clickOutside(event) {
    var $target = $(event.target);

    if( $target.parents('.modal-content').length || $target.hasClass('modal-content') ) return;

    $(event.target).parents('.modal-wrapper').removeClass('open');
    $(event.target).parents('.js-modal').addClass('hidden');
  }
}

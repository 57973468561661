import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "img" ]

  connect(){
  }

  submitForm(){
    var e = event.target
    var form = e.closest('form')
    //form.submit();
    Rails.fire(form, 'submit')
    e.disabled = true
    var elem = document.createElement("img");
    elem.setAttribute("src", this.data.get('src'));
    elem.setAttribute("style", "padding: 5px 0;");
    elem.setAttribute("height", "10");
    elem.setAttribute("width", "40");
    elem.setAttribute("alt", "loading ...");
    this.getNextElement(e, '.js-slot-checkbox-content')
    //e.next('.js-slot-checkbox-content').html(elem)
  }

  getNextElement(elem, className) {
      var next = elem.nextElementSibling; // set next element to "nextElementSibling" relative to passed element.
      while (next && !next.classList.contains(className)) { // check for existence and class
          next = next.nextElementSibling; // if it exists, but the class does not, move to the next element and repeat.
      }
      return next; // return whatever was found, or null
  }

}

import { Controller } from "stimulus"

export default class extends Controller {

  connect(){
    var target = $(this.element)
    console.log(target)
    if(target.is(':checked')){
      target.closest('.js-toggle-checkbox').addClass('is-checked');
    }
    else{
      target.closest('.js-toggle-checkbox').removeClass('is-checked');
    }
  }

  checkNotifs(){
    var e = event.target
    var form = e.closest('form')
    e.closest('.js-toggle-checkbox').classList.toggle('is-checked');
    Rails.fire(form, 'submit')
  }
}

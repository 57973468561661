import { Controller } from "stimulus"

export default class extends Controller {

  connect(){
    var defaultDate = this.data.get("date")
    var flatpickr_share_date_config = {
      defaultDate: defaultDate,
      altFormat: "d/m/Y",
      altInput: true
    }

    $('#datepicker:not(.flatpickr-input)').flatpickr(flatpickr_share_date_config);
  }

}

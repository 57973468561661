import { Controller } from "stimulus"

export default class extends Controller {

  static targets = [ "input", "image" ]

  connect(){
    this.imageVisible = this.data.get('image-visible');
    this.imageInvisible = this.data.get('image-invisible');
  }

  setVisible() {
    $(this.inputTarget).attr('type','text');
    $(this.imageTarget).attr('src',this.imageVisible);
  }

  setInvisible() {
    $(this.inputTarget).attr('type','password');
    $(this.imageTarget).attr('src',this.imageInvisible);
  }

  toggleVisible() {
    if($(this.inputTarget).attr('type') == 'password') {
      $(this.inputTarget).attr('type','text');
    } else {
      $(this.inputTarget).attr('type','password');
    }
    if($(this.imageTarget).attr('src') == this.imageInvisible) {
      $(this.imageTarget).attr('src',this.imageVisible);
    } else {
      $(this.imageTarget).attr('src',this.imageInvisible);
    }
  }
}

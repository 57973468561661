import { Controller } from "stimulus"

export default class extends Controller {

  upload(){
    var e = $(event.target)
    var files = event.target.files;
    var image = files[0]
    var formData = new FormData();
    var id = e.closest('label').attr('data-label-id')
    var next_icon = e.next('.ico_add')
    var elem = document.createElement("img");
    elem.setAttribute("src", this.data.get('src'));
    elem.setAttribute("height", "50");
    elem.setAttribute("width", "50");
    elem.setAttribute("alt", "loading ...");
    formData.append('document[file]', image, image.name);
    formData.append('document[doc_type]', "sports-trainer-card" );
    formData.append('myinfo', "true" );
    formData.append('id', id);
    $.ajax
      ({
          url: "/user_document_upload",
          data: formData,
          type: 'post',
          processData: false,
          contentType: false,
          beforeSend: function(){
            next_icon.html(elem)
          }
      });
  }

  delete(){
    event.preventDefault();
    var e = $(event.target)
    var lab_id = e.parent().attr("data-label-id")
    var pic_id = e.parent().attr("data-pic-id")
    $.ajax
      ({
          url: "/user_document_delete",
          data: {'id': pic_id, 'lab_id': lab_id},
          type: 'post',
      });
  }

}

import { Controller } from "stimulus"

export default class extends Controller {

  connect(){
    var defaultTime = this.data.get("time")

    var flatpickr_share_config = {
      enableTime: true,
      noCalendar: true,
      dateFormat: "H:i",
      time_24hr: true,
      defaultDate: defaultTime
    }
    $('#timepicker:not(.flatpickr-input)').flatpickr(flatpickr_share_config);
  }

}

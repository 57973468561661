import { Controller } from "stimulus"

export default class extends Controller {

  upload(){
    var e = $(event.target)
    var files = event.target.files;
    var image = files[0]
    var formData = new FormData();
    var id = e.closest('label').attr('data-label-id')
    var next_icon = e.next('.ico_add')
    var submit_button = $('.next-button')
    var elem = document.createElement("img");
    elem.setAttribute("src", this.data.get('src'));
    elem.setAttribute("height", "50");
    elem.setAttribute("width", "50");
    elem.setAttribute("alt", "loading ...");
    formData.append('image', image, image.name);
    formData.append('id', id);
    $.ajax
      ({
          url: "/activity_pictures_upload",
          data: formData,
          type: 'post',
          processData: false,
          contentType: false,
          beforeSend: function(){
            next_icon.html(elem)
            submit_button.attr('disabled', true)
            submit_button.css('cursor', 'not-allowed')
          },
          success: function(res){
            submit_button.attr('disabled', false)
            submit_button.css('cursor', 'pointer')
          }
      });
  }

  delete(){
    event.preventDefault();
    var e = $(event.target)
    var lab_id = e.parent().attr("data-label-id")
    var submit_button = $('.next-button')
    if (e.parent().data('pic-id')){
      var activity_id = this.data.get('activity-id')
      var pic_id = e.parent().attr("data-pic-id")
      $.ajax
        ({
            url: "/activity_picture_delete",
            data: {'id': pic_id, 'lab_id': lab_id, 'activity_id': activity_id},
            type: 'post',
            beforeSend: function(){
            submit_button.attr('disabled', true)
            submit_button.css('cursor', 'not-allowed')
            },
            success: function(res){
              submit_button.attr('disabled', false)
              submit_button.css('cursor', 'pointer')
            }
        });
    }
    else if (e.parent().data('up-id')) {
      var up_id = e.parent().attr("data-up-id")
      $.ajax
        ({
            url: "/activity_uploader_delete",
            data: {'id': up_id, 'lab_id': lab_id},
            type: 'post',
            beforeSend: function(){
            submit_button.attr('disabled', true)
            submit_button.css('cursor', 'not-allowed')
            },
            success: function(res){
              submit_button.attr('disabled', false)
              submit_button.css('cursor', 'pointer')
            }
        });
    }
  }

}
